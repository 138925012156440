<template>
  <div>
    <div class="container pt-3 pb-1">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" v-if="topic">
          <li class="breadcrumb-item">
            <router-link to="#"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/admin/courses">Kursus</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="`/admin/courses/${topic.attributes.course.data.id}`"
              >{{ topic.attributes.course.data.attributes.course_name }}</router-link
            >
          </li>
          <li class="breadcrumb-item active">
            {{ topic.attributes.topic_name }}
          </li>
        </ol>
      </nav>
    </div>
    <!-- <div class="header-full">
      <div class="container">
        <div class="container-header">
          <div class="float-end item-right">
            <button
              class="btn btn-outline-secondary"
              data-bs-toggle="modal"
              data-bs-target="#addSubtopicModal"
            >
              Tambah Subtopik +
            </button>
            <input
              type="text"
              class="form-control"
              v-model="searchValue"
              @change="searchSubtopic"
              placeholder="Carian subtopik..."
            />
          </div>

          <h2 v-if="topic">{{ topic.attributes.topic_name }}</h2>
        </div>
      </div>
    </div> -->
    <div class="header-full">
      <div class="container">
        <div class="container-header">
          <div class="row align-items-center px-3">
            <div class="col-md-6">
              <h2 v-if="topic">{{ topic.attributes.topic_name }}</h2>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-between">
                <button
                  class="btn btn-outline-secondary w-50 me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addSubtopicModal"
                >
                  Tambah Subtopik +
                </button>
                <input
                  type="text"
                  class="form-control "
                  v-model="searchValue"
                  @change="searchSubtopic"
                  placeholder="Carian topik..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-full">
      <div class="container pt-3 pb-4">
        <div class="row mx-0">
          <div class="col-md-8">
            <div class="container-box">
              <h5>Senarai Subtopik</h5>
              <table
                class="table table-hover table-borderless mt-3"
                width="100%"
                style="table-layout: fixed"
              >
                <thead>
                  <tr>
                    <th width="4%">#</th>
                    <th width="42%">Nama Subtopik</th>
                    <th scope="col">Dicipta pada</th>
                    <th width="12%">Kuiz</th>
                    <th scope="col">Tindakan</th>
                  </tr>
                </thead>
                <tbody
                  v-if="topic && filteredSubtopics && filteredSubtopics.length > 0"
                >
                  <tr
                    v-for="(subtopic, ind) in filteredSubtopics"
                    :key="subtopic.id"
                  >
                    <td scope="row">{{ ind+1 }}</td>
                    <td>
                      <router-link
                        :to="`/admin/subtopics/${subtopic.id}`"
                        >{{ subtopic.attributes.subtopic_name }}</router-link
                      >
                    </td>
                    <td>{{ getDate(subtopic.attributes.createdAt) }}</td>
                    <td>
                      <span
                        v-if="!subtopic.attributes.has_quiz"
                        class="badge bg-danger"
                        >TIADA</span
                      >
                      <span v-else class="badge bg-success">YA</span>
                    </td>
                    <td>
                      <router-link
                        class="me-2"
                        :to="`/admin/subtopics/${subtopic.id}`"
                        ><i class="fas fa-pen"></i
                      ></router-link>
                      <span class="ms-2" @click="deleteSubtopic(subtopic)"
                        ><i class="fas fa-trash"></i
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row mx-0">
              <div class="col-12 mt-3">
                <div class="container-box">
                  <h5>Tetapan</h5>
                  <div class="form-group mt-3 custom-checkbox">
                    <label for="name" class="control-label mb-1">Kuiz</label
                    ><br />

                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="topicForm.has_quiz"
                      id="kuiz"
                    />
                    <label class="form-check-label ms-3" for="kuiz">
                      {{ topicForm.has_quiz ? "Aktif" : "Tidak aktif" }}
                    </label>
                  </div>
                  <div class="pt-4">
                    <button
                      class="btn btn-primary"
                      v-if="topic && (topicForm.has_quiz != topic.attributes.has_quiz )"
                      @click="submitUpdateTopic()"
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal"
      id="addSubtopicModal"
      tabindex="-1"
      aria-labelledby="addSubtopicModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-12">
                <h5>Tambah Subtopik Baru</h5>
                <p>
                  Sila tambah kursus mengikut kriteria-kriteria seperti yang
                  tertera.
                </p>

                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Nama Subtopik</label
                  >
                  <input
                    type="text"
                    v-model="subtopicForm.subtopic_name"
                    class="form-control"
                    placeholder="Masukkan nama subtopik"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Tentang Subtopik</label
                  >
                  <textarea
                    class="form-control"
                    v-model="subtopicForm.description"
                    rows="3"
                    placeholder="Penerangan subtopik"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4">
            <button
              type="button"
              class="btn btn-primary btn-full"
              @click="submitAddSubtopic"
            >
              Tambah
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import API from '../../utils/API'
import $ from 'jquery'

export default {
    data() {
        return {
            topic: null,
            filteredSubtopics: null,
            fileList: null,
            searchValue: '',
            subtopicForm: {
                subtopic_name: null,
                description: null,
                topic: this.$route.params.topicid
            },
            topicForm: {
                fileListForm: [],
                files: [],
                has_quiz: null
            }
        }
    },
    methods: {
        async getTopic() {
            const res = await API.get(`topics/${this.$route.params.topicid}?populate=%2A`)
            this.topic = res.data.data
            this.filteredSubtopics = res.data.data.attributes.subtopics.data ? [...res.data.data.attributes.subtopics.data] : null
            this.fileList = res.data.data.attributes.files.data ? [...res.data.data.attributes.files.data] : null
            this.topicForm = { ...this.topic.attributes }
        },
        goto(_path){
            this.$router.push(_path)
        },
        getDate(date) {
            return moment(date).format("DD MMM YYYY")
        },
        getTimeTaken(time) {
            if (time) {
                let timeArr = time.split(':')
                return timeArr[0] + ' jam ' + timeArr[1] + ' minit '
            } else return '-'

        },
        searchSubtopic() {
            if (this.searchValue !== '') {
                this.filteredSubtopics = this.topic.attributes.subtopics.data.filter(o => {
                    return o.attributes.subtopic_name.toUpperCase().indexOf(this.searchValue.toUpperCase()) == -1 ? false : true;
                })
            } else {
                this.filteredSubtopics = this.topic.attributes.subtopics.data
            }
        },
        fileUploaded() {
            if(this.$refs.relatedFile && this.$refs.relatedFile.files[0]) {
                this.topicForm.fileListForm.push(this.$refs.relatedFile.files[0])
            }
        },
        async uploadFile() {
            let files = [];
            for (let i = 0; i < this.topicForm.fileListForm.length; i++) {
                let formData = new FormData()
                formData.append('files', this.topicForm.fileListForm[i])
                const resFileUpload = await API.post('upload', formData)
                files.push(resFileUpload.data[0].id)
            }

            return this.fileList ? files.concat(this.fileList.map(a => a.id)) : files
        },
        timeConvert(total) {
            const hours = Math.floor(total/60) > 9 ? Math.floor(total/60) : '0' + Math.floor(total/60)
            const mins = total % 60 > 9 ? total % 60 : '0' + total % 60
            return `${hours}:${mins}:00`
        },
        async deleteSubtopic(subtopic) {
            const res = await API.delete(`subtopics/${subtopic.id}`)

            if (res.toDelete) {
                let index = this.filteredSubtopics.findIndex(item => item.id == subtopic.id)
                this.filteredSubtopics.splice(index, 1)
            }
        },
        async submitAddSubtopic() {
            const res = await API.post('subtopics', { data: this.subtopicForm })
            this.searchValue = ''
            this.topic.attributes.subtopics.data.push(res.data.data)
            this.filteredSubtopics = [...this.topic.attributes.subtopics.data]
            this.subtopicForm.subtopic_name = null
            this.subtopicForm.description = null
            $('#addSubtopicModal').toggle()
            $('.modal-backdrop').remove()
        },
        async submitUpdateTopic() {

            const res = await API.put(`topics/${this.$route.params.topicid}`, { data: {
                has_quiz : this.topicForm.has_quiz
            }
            })
            this.topicForm.has_quiz = res.data.data.attributes.has_quiz;
            await this.getTopic()
        }


    },
    async mounted() {
        await this.getTopic()
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";
</style>
